import { SetStateAction, useState } from "react";
import styles from "./Home.module.scss";
import MainContainer from "../../Components/UI/MainContainer/MainContainer";
import mainLogo from "../../assets/images/mainLogo.svg";
// import title from "../../assets/images/whitelist-checker-title.png";
import subtitle from "../../assets/images/whitelist-checker-subtitle.png";
import { Button, Input, Text } from "@chakra-ui/react";
import { whiteList } from "../../constants/whiteList";

const Home = () => {
  const [inputAddress, setInputAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tokenWhitelisted, setTokenWhitelisted] = useState<boolean | null>(
    null
  );

  const checkTokenWhitelistAddress = async () => {
    const isWhiteList = whiteList.filter(
      (address) =>
        inputAddress.toLowerCase().trim() === address.toLowerCase().trim()
    );
    if (isWhiteList.length) {
      setTokenWhitelisted(true);
    } else {
      setTokenWhitelisted(false);
    }
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;

  const handleChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setInputAddress(event.target.value);
    console.log(event.target.value);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    checkTokenWhitelistAddress();
    setIsLoading(false);
  };
  return (
    <div className={styles.home}>
      <MainContainer className={styles.homeContainer}>
        <img
          src={mainLogo}
          alt="rats"
          style={{ width: "50%", marginLeft: "-10%" }}
        />
        {/* <img src={title} alt="rune rat whitelist checker" /> */}
        <img
          src={subtitle}
          alt="enter your address to check"
          style={{ maxWidth: isMobile ? "100%" : "75%", height: "30px" }}
        />
        <div className={styles.inputWrapper}>
          <Input
            placeholder="Ordinals Wallet"
            _placeholder={{ color: "white" }}
            value={inputAddress}
            onChange={handleChange}
            style={{ color: "white" }}
          />
          <Button
            variant="outline"
            color={"white"}
            onClick={handleSubmit}
            isLoading={isLoading}
            className={styles.button}
          >
            CHECK
          </Button>
        </div>
        <div>
          {tokenWhitelisted !== null && (
            <Text color="white" whiteSpace="nowrap" fontSize={"24px"}>
              {tokenWhitelisted
                ? "Congratulations! You're eligible to participate in the token launch."
                : "You are not on the whitelist, sorry!"}
            </Text>
          )}
        </div>
      </MainContainer>
    </div>
  );
};

export default Home;
