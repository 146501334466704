import React from "react";
import { Outlet } from "react-router-dom";
// import Header from "./Components/Header/Header";
import NewHeader from "./Components/NewHeader/NewHeader";
// import Footer from "./Components/Footer/Footer";
import styles from "./App.module.css";

function App() {
  return (
    <div className={styles.app}>
      <NewHeader />
      <Outlet />
      {/* <Footer/> */}
    </div>
  );
}

export default App;
